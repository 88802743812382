var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import { Button } from 'element-ui';
import { vRow, vColumn, vBackground, vAnimate, vAnimateItem, vAnimateTiming } from "$components/index";
import layoutCase from '$layout/layout-case';
import servicesData from "../data/services";
import advantageData from '../data/advantage';
import choiceData from '../data/choice';
import cashData from '../data/case';
import cooperationData from '../data/cooperation';
import vFooter from '$views/footer';
import { Swiper, SwiperItem, vImage, vLocalImage } from "$components/index";
import ScrollView from "$components/scroll-view/src/main.vue";
import require_image from "$utils/images/require_image";
let default_1 = class extends Vue {
    constructor() {
        super(...arguments);
        this.bannerBackground = require_image('images/background.png');
        // 公司背景图片
        this.aboutBackground = require_image('images/about-background.png');
        this.aboutMobileBackground = require_image('images/about-background-mobile.png');
        this.aboutMobileHeader = require_image('images/about-mobile-header.png');
        // 服务范围背景图片
        this.servicesBackground = require_image('images/services-background.png');
        // 服务范围 数据
        this.servicesData = servicesData;
        // 我的优势背景图片
        this.advantageBackground = require_image('images/advantage-background.png');
        // 我的优势 数据
        this.advantageData = advantageData;
        // 选择我们
        this.choiceBackground = require_image('images/choice-background.png');
        this.choiceData = choiceData;
        // 案例
        this.cashData = cashData;
        // 合作伙伴
        this.cooperationBackground = require_image('images/cooperation-background.png');
        // 合作伙伴数据
        this.cooperationData = cooperationData;
    }
    scroll(scroll) {
        return this.$refs.animate && this.$refs.animate.animate(scroll);
    }
};
default_1 = __decorate([
    Component({
        components: {
            ScrollView,
            [Button.name]: Button,
            Swiper,
            SwiperItem,
            vImage,
            vLocalImage,
            vRow,
            vColumn,
            layoutCase,
            vBackground,
            vFooter,
            vAnimate, vAnimateItem, vAnimateTiming
        }
    })
], default_1);
export default default_1;
