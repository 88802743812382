import require_image from "$utils/images/require_image";
export default [
    {
        icon: require_image('images/cooperation/1.png')
    },
    {
        icon: require_image('images/cooperation/2.png')
    },
    {
        icon: require_image('images/cooperation/3.png')
    },
    {
        icon: require_image('images/cooperation/4.png')
    },
    {
        icon: require_image('images/cooperation/5.png')
    },
    {
        icon: require_image('images/cooperation/6.png')
    },
    {
        icon: require_image('images/cooperation/7.png')
    },
    {
        icon: require_image('images/cooperation/8.png')
    }
];
