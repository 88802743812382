import require_image from "$utils/images/require_image";
export default [
    {
        label: 'APP开发',
        icon: require_image('images/services/app.png'),
        content: [
            '完全个性化设计',
            '专属定制研发应用软件',
            '为您提供多方位解决方案'
        ]
    },
    {
        label: '网站定制开发',
        icon: require_image('images/services/pc.png'),
        content: [
            '需求分析—创意设计—程序制作',
            '—资料添加—测试交付',
            '每一步我们都追求精致'
        ]
    },
    {
        label: '微信开发',
        icon: require_image('images/services/wechat.png'),
        content: [
            '基于微信为企业提供开发、运营、',
            '培训、推广一体化解决方案',
            '帮助企业实现线上线下业务互通'
        ]
    },
    {
        label: '软件定制',
        icon: require_image('images/services/custom.png'),
        content: [
            '根据企业行业特点和',
            '自身需求整合企业网络资源',
            '提供长期的网络营销服务支持'
        ]
    }
];
