import require_image from "$utils/images/require_image";
export default [
    {
        label: '针对性强定制开发',
        labels: [
            '针对性强',
            '定制开发'
        ],
        icon: require_image('images/choice/1.png')
    },
    {
        label: '服务响应及时快速',
        labels: [
            '服务响应',
            '及时快速'
        ],
        icon: require_image('images/choice/2.png')
    },
    {
        label: '专业开发严谨测试',
        labels: [
            '专业开发',
            '严谨测试'
        ],
        icon: require_image('images/choice/3.png')
    },
    {
        label: '保证工期、按时交付',
        labels: [
            '保证工期',
            '按时交付'
        ],
        icon: require_image('images/choice/4.png')
    },
    {
        label: '正规大型企业',
        icon: require_image('images/choice/5.png')
    },
    {
        label: '协助三方资料对接',
        labels: [
            '协助三方',
            '资料对接'
        ],
        icon: require_image('images/choice/6.png')
    }
];
